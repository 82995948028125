import BaseTextComponent from "@/components/base/el/BaseTextComponent";
import {get_esc_data, get_att_data, is_object} from "@/components/base/utils";
// import {is_array} from "@/components/base/utils"

export default {
    name: "BaseSelect2Component",
    mixins: [BaseTextComponent],
    render(createElement) {
        return this.make_element_tree(createElement, this.component_data);
    },
    data() {
        return {
            is_text: false,
            isSelect2: true,
            interval: ''
        };
    },
    async created() {
    },
    computed: {
        data: {
            get() {
                return JSON.parse(this.component_data.attr['data'].replaceAll("'", '"')).filter(e => {
                    if (e.id) {
                        return e.id
                    }
                })
            },
            set(val) {
                console.log(val);
            }
        },
        options() {
            return JSON.parse(this.component_data.attr['options'].replaceAll("'", '"'))
        }
    },
    mounted() {
        // if (this.$store.state.edit_mode) {
        //     this.interval = setInterval(() => {
        //         if (window.$ && window.$(this.$el).children('select') && window.$(this.$el).children('select').hasClass("select2-hidden-accessible")) {
        //             console.log("VDsvdsvd");
        //             window.$(this.$el).children('select').select2('destroy')
        //             clearInterval(this.interval)
        //         }

        //     }, 500)
        // }
    },
    methods: {
        focus_out() {
        },
        save(e,field, path) {
            let data = [{text: '',id:''},...this.data]
            if (e.target.value) {
                data[path+1][field] = e.target.value
                this.component_data.attr['data'] = JSON.stringify(data)
                window.$(this.$el).children('select').select2().empty().trigger("change").select2({
                    ...this.options,
                    data: data
                })
            }
        },
        addItem() {
            let data = JSON.parse(JSON.stringify(this.data))
            data.push(data[data.length - 1])
            console.log(data);
            this.component_data.attr['data'] = JSON.stringify(data)
            window.$(this.$el).children('select').select2().empty().trigger("change").select2({
                ...this.options,
                data: data
            })
        },
        removeItem() {
            let data = JSON.parse(JSON.stringify(this.data))
            data.pop()
            this.component_data.attr['data'] = JSON.stringify(data)
            window.$(this.$el).children('select').select2().empty().trigger("change").select2({
                ...this.options,
                data: data
            })
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children

            if (element_data.attr && "deepsel-select2" in element_data.attr) {
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    style: this.styling,
                    on: {
                        focusout: this.focus_out,
                        // input: this.on_input,
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            for (let key in options) data[key] = options[key]
            
            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data) {
            let el = element_data.tag
            let props = null
            return [el, props]
        },
        get_attrs(element_data) {
            let attrs = {}
            if (element_data.attr) {

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }

                this.replace_origin_urls(attrs, element_data)

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement) {
            let children = []
            let esc_data = get_esc_data(element_data, this.$store.state.common_data)
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        if (item.tag === "link" && item.attr && item.attr.href) {
                            if (item.attr.href.includes("bootstrap")) continue;
                        }

                        children.push(this.make_element_tree(createElement, item));

                    } else if (item.node === "text") {
                        let esc_data = get_esc_data(element_data, this.$store.state.common_data)
                        if (esc_data) {
                            children.push(esc_data)
                        } else children.push(item.text);
                    }
                }
                if (!element_data.child.find(e => e.node === "text") && esc_data) {
                    children = [esc_data]
                }

                if (this.transform && "deepsel-select2" in element_data.attr) {
                    children.push(createElement("style", [this.transform]))
                }
            } else if (esc_data) {
                children.push(esc_data);
            }

            return children
        },
    },
}